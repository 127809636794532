
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import VJsfForm from '@/components/commonComponents/VJsfForm.vue';
import { FetchPolicy } from 'apollo-client';
import EventBus from '@/event-bus';
import {
  Valid,
  Schema,
  VJsf,
  Options,
} from '@/types/vjsf';
import { Operation } from '@/graphql/types';
import Message from '@/components/mixins/Message.vue';
import useScreenHandlerStore from '@/stores/screenHandler';

@Component({
  name: 'FormBlock',
  components: {
    BaseCard,
    VJsfForm,
  },
  data: () => ({
    model: {},
    initialModel: {},
    schema: { type: 'object', properties: {} },
    options: {} as Options,
    valid: null,
    metadata: undefined,
  }),
})
export default class FormBlock extends Vue {
  @Prop({ required: true })
  protected readonly navigationSlug!: string;

  @Prop({ required: true })
  protected readonly dossierTypeSlug!: string;

  @Prop({ required: true })
  protected readonly dossierId!: number;

  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop()
  private readonly context?: string;

  @Prop({ default: false })
  private readonly autofocusFirstField!: boolean;

  @Prop()
  private readonly fetchEndpoint?: string;

  protected loading = false;

  private screenHandler = useScreenHandlerStore();

  private valid: Valid = true;

  private graphqlVariables = {};

  mounted(): void {
    this.graphqlVariables = {
      dossierId: this.dossierId,
      blockId: this.blockId,
      context: this.context,
      navigationSlug: this.navigationSlug,
      dossierTypeSlug: this.dossierTypeSlug,
    };
    this.fetchData('network-only');

    const forceRerender = () => this.fetchData('network-only');

    EventBus.$on('forceRerender', forceRerender);

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('forceRerender', forceRerender);
    });
  }

  private validate(): boolean {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  private async fetchDataKeepModel(): Promise<void> {
    const currentModel = { ...this.$data.model };

    await this.fetchData('network-only');

    this.$data.model = currentModel;
  }

  private deleteData(): void {
    this.loading = true;

    const variables = this.graphqlVariables;

    import('@/graphql/operations/dossier-type-form-block-by-id')
      .then(({ DELETE: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        if (response.data.successful === true) {
          this.screenHandler.reset();

          Message.success(this.$t('delete.notification'));
          if (this.$route.hash !== '') {
            this.$emit('deleted');
          } else {
            const routerData = this.$router.resolve({ name: 'dossier-type-list', params: this.$route.params }).location;
            this.$router.push(routerData);
          }
        } else {
          Message.error(this.$t('generic.error.occurred'));
        }
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private submitForm(): void {
    this.loading = true;

    Object.keys(this.$data.schema.properties).forEach((fieldName) => {
      if (this.$data.model[fieldName] === undefined) {
        this.$data.model[fieldName] = '';
      }
    });

    const variables = {
      data: this.$data.model,
    };
    Object.assign(variables, this.graphqlVariables);

    this.import()
      .then(({ MUTATION: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        this.setVJsfForm(response.data.form);
      })
      .then(() => {
        if (this.valid) {
          Message.success(this.$t('add.notification'));
          EventBus.$emit('initializeForceRerender');
          this.fetchData('no-cache');
        } else {
          Message.error(this.$t('generic.error.occurred'));
        }
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async fetchData(fetchPolicy: FetchPolicy = 'network-only'): Promise<void> {
    this.loading = true;

    return this.import()
      .then(({ QUERY: query }) => this.$apollo.query({
        query,
        fetchPolicy,
        variables: this.graphqlVariables,
      }))
      .then((response) => {
        this.setVJsfForm(response.data.form);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
        this.$emit('rendered');
      });
  }

  private setVJsfForm(formObject: VJsf): void {
    const {
      schema, options, model, valid, metadata,
    } = formObject;

    this.$data.schema = this.addAutofocusToSchema(schema);
    this.$data.options = { ...options, ...{ locale: this.$vuetify.lang.current } };
    this.$data.model = model;
    this.$data.initialModel = { ...model };
    this.$data.metadata = metadata;
    this.valid = valid;
  }

  /**
   * Add the autofocus property to x-props first the first visible input field if
   * autofocusFirstField is true
   *
   * @param currentSchema
   * @private
   */
  private addAutofocusToSchema(currentSchema: Schema): Schema {
    const schema = currentSchema;
    const firstFocusableInput: string|undefined = Object.keys(schema.properties)
      .find((propertyName: string) => schema.properties[propertyName].type !== 'hidden');

    if (this.autofocusFirstField && typeof firstFocusableInput !== 'undefined') {
      schema.properties[firstFocusableInput]['x-props'] = {
        ...schema.properties[firstFocusableInput]['x-props'],
        ...{ autofocus: true },
      };
    }
    return schema;
  }

  private import(): Promise<Operation> {
    if (this.fetchEndpoint) {
      return import(`@/graphql/operations/${this.fetchEndpoint}`);
    }
    return import('@/graphql/operations/dossier-type-form-block-by-id');
  }
}
